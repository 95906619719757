html {
  font-size: inherit;
}

.login-section .login {
  display: flex;
  min-height: 100vh;
}

.login-section .login .login-left .left-bg {
  height: 100%;
}

.login-section .login .login-left span {
  color: white;
  font-size: 16px;
  -webkit-text-stroke: 0.2px black;
}

.login-section .login .login-left {
  width: 50%;
  background-image: url(../images/login/login-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-section .login .login-right {
  width: 50%;
  padding: 160px 100px 250px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.login-section .login .login-right .center {
  align-self: center;
}

.login-section .login .login-right img {
  max-width: 180px;
}

.login-section .login .login-right .login-form h1 {
  color: var(--color-dark-blue-1);
  font-weight: bold;
  font-size: 30px;
  margin: 15px 0px 30px 0px;
}

.login-section .login .login-right .sub-txt {
  color: var(--color-light-blue-1);
  text-decoration: underline;
  margin-top: 2%;
  display: inline-block;
  margin-top: 8px;
  font-size: 14px;
  line-height: 17px;
  color: #0071bd;
}

.login-section .login .login-right .box-div {
  padding: 20px 0px;
}

.login-section .login .login-right .main-txt {
  color: var(--color-dark-blue-1);
  font-weight: bold;
}

.login-section .login .login-right .login-form .btn {
  display: block;
  padding: 20px 0px;
  background-color: var(--color-light-blue-1);
  border-radius: 15px;
  color: white;
  text-align: center;
  font-weight: bold;
  margin: 30px 0px;
}

.sub-link-2 {
  margin-top: 5px;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #2f80ed;
}

.login-section .login .login-right .stroke {
  height: 1px;
  background-color: #ececec;
  width: 100%;
}

.links a {
  padding-right: 5px;
  padding-left: 5px;
  border-right: 1px solid #2f80ed;
}

.login-form-radios {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
  background-color: #f8f8f8;
  border-radius: 20px;
}

.login-form-radios .boxes {
  min-height: 15rem;
  background-color: #f8f8f8;
  padding: 1rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form-radios .boxes label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.login-form-radios .box {
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 1.2rem;
  /* padding-top: 0.7rem; */
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.8rem;
  cursor: pointer;
}

.login-form-radios .box:last-child {
  margin-bottom: 0;
}

.login-form-radios .flex-center-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login-form-radios .box svg {
  color: #ccd5e1;
  background-color: #ccd5e1;
  border-radius: 50%;
  position: absolute;
  font-size: 20px;
  top: -3px;
  left: -3px;
  height: 20px;
  width: 20px;
}

.login-form-radios .rb-container {
  position: relative;
}

.login-form-radios .boxClicked {
  background: #ffffff;
  border: 1px solid #fff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.login-form-radios .boxClicked svg {
  display: block;
  color: #f9ae3b;
  font-size: 20px;
  background-color: #fff;
  position: absolute;
  top: -3px;
  left: -3px;
  height: 20px;
  width: 20px;
}

.login-form-radios .boxClicked .boxTitle {
  color: #3f3f3f;
  font-weight: 600;
}

.login-form-radios .boxClicked .boxContent {
  color: #9b9b9b;
}

.login-form-radios .boxTitle {
  margin-left: 1.5rem;
  font-size: 1.125rem;
  color: #6681a6;
  font-family: 'Montserrat';
}

.login-form-radios .boxContent {
  margin: 0.7rem 0.7rem 0.7rem 0rem;
  font-family: 'Montserrat';
  color: #6681a6;
  font-size: 1rem;
}

.login-form-radios .box::before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #66a9d7;
  position: relative;
  left: 16px;
}

.login-form-radios .box.boxClicked::before {
  background-color: #ffffff;
  background-image: url(../images/home/check.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  left: 16px;
}

.login-left__content-block {
  position: absolute;
  top: 70px;
  left: 80px;
}

.login-left__content-block img {
  display: block;
  margin-bottom: 10px;
  width: 103px;
  height: 31px;
}

.login-left__content-block h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18.506px;
  line-height: 23px;
  color: #ffffff;
}

@media (max-width: 959px) {
  .login {
    flex-wrap: wrap;
  }

  .login-section .login {
    min-height: auto;
  }

  .login-section .login .login-right {
    width: 100%;
  }

  .login-section .login .login-left {
    min-height: 120vw;
    width: 100%;
    background: url(../images/login/login-bg-rocket.svg),
      url(../images/login/login-bg-drops.svg),
      linear-gradient(203.43deg, #1179bf 30.65%, #012d6c 72.63%);
    background-size: 110vw, cover, cover;
    background-position: bottom -23vw right -5vw, center, center;
    background-repeat: no-repeat, repeat, repeat;
  }

  .login-left__content-block {
    display: none;
  }

  .login-section .login .login-right img {
    width: 105px;
    height: 32px;
    display: block;
    margin: 0 auto 10px;
  }

  .login-section .login .login-right .login-form h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #012d6c;
    text-align: center;
  }

  .login-section .login .login-right {
    padding: 30px 24px 130px;
  }
}
